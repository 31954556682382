import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Link from "next/link";
import { Logo, NextLink } from "@src/components";
import { getClassNameActive } from "../config";
import SideNavWithSubMenu from "./side-nav-with-sub-item";
import { useRouter, usePathname } from "next/navigation";
import { useLayoutContext } from "@src/layouts/provider";
const SideNav = props => {
  const {
    open,
    onClose
  } = props;
  const router = useRouter();
  const pathname = usePathname();
  const {
    menuConfigList
  } = useLayoutContext();
  const handleRedirect = path => () => {
    router.push(path);
    onClose();
  };
  const content = <Box sx={{
    display: "flex",
    flexDirection: "column",
    height: "100%"
  }}>
      <Box component={Link} href="/" sx={{
      display: "inline-flex",
      alignItems: "center",
      padding: "0 16px",
      width: "100%",
      height: 56,
      "& svg": {
        height: 16,
        width: "auto"
      }
    }}>
        <Logo />
      </Box>
      <Divider sx={{
      borderColor: "#E8E8E8"
    }} />
      <Box component="nav" sx={{
      flexGrow: 1
    }}>
        {menuConfigList.map((item, index) => item?.subMenu ? <SideNavWithSubMenu currentPath={pathname} data={item} key={index} onRedirect={handleRedirect} /> : <NextLink key={index} className={getClassNameActive(item, pathname)} href={item.path} sx={{
        "& a": {
          display: "flex",
          alignItems: "center",
          padding: "0 16px",
          minHeight: 32,
          width: "100%",
          color: `${item.color || "#000"} !important`,
          borderBottom: "1px solid #E5E5E5",
          textDecoration: "none",
          "&.active": {
            color: `${item.color || "#000"} !important`,
            backgroundColor: "#F6F6F6 !important"
          }
        }
      }} onClick={onClose}>
              {item.title}
            </NextLink>)}
      </Box>
    </Box>;
  return <Drawer anchor="left" onClose={onClose} open={open} PaperProps={{
    sx: {
      backgroundColor: "#FFF",
      color: "#FFF !important",
      width: "50%",
      maxWidth: "207px",
      borderRadius: "0px"
    }
  }} sx={{
    zIndex: theme => theme.zIndex.appBar + 100
  }} variant="temporary" data-sentry-element="Drawer" data-sentry-component="SideNav" data-sentry-source-file="index.tsx">
      {content}
    </Drawer>;
};
export default SideNav;