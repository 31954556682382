import * as React from "react";
import { Icons } from "@src/components";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import { usePopover } from "@src/hooks";
import { styled } from "@mui/system";
import { getClassNameActive } from "../config";
const MenuItemCustom = styled(MenuItem)(({
  theme
}) => ({
  color: "#141414 !important",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "24px",
  padding: "0 16px",
  minHeight: "48px !important",
  minWidth: 200,
  borderBottom: "1px solid #E5E5E5",
  textTransform: "uppercase",
  "&:hover": {
    color: "#000 !important",
    backgroundColor: "#F6F6F6 !important"
  },
  "&.active": {
    color: "#000 !important",
    backgroundColor: "#F6F6F6 !important"
  }
}));
const MenuWithSubItem = props => {
  const {
    currentPath,
    data,
    onRedirect
  } = props || {
    data: {}
  };
  const popoverHook = usePopover();
  const handleClickItem = path => () => {
    onRedirect(path)();
    popoverHook.handleClose();
  };
  return <>
      <div style={{
      height: "100%"
    }} ref={popoverHook.anchorRef}>
        <Stack sx={{
        display: "flex",
        whiteSpace: "nowrap",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        minHeight: "auto ",
        height: "100%",
        outline: 0,
        cursor: "pointer",
        fontSize: 16,
        color: "#434343",
        "&:hover": {
          color: "#000 !important",
          fontWeight: 700
        },
        "&.active": {
          color: "#000 !important",
          fontWeight: 700
        }
      }} className={popoverHook?.open || getClassNameActive(data, currentPath) ? "active" : ""} role="button"
      //  hover to open popover
      onClick={handleClickItem(data.path)} onMouseEnter={popoverHook.handleOpen} onMouseLeave={popoverHook.handleClose} data-sentry-element="Stack" data-sentry-source-file="menu-with-sub-item.tsx">
          {data.title}
          <Icons name="arrow-up" sx={{
          marginLeft: "2px",
          transition: "transform 0.15s",
          transform: popoverHook?.open ? "rotate(0deg)" : "rotate(180deg)"
        }} data-sentry-element="Icons" data-sentry-source-file="menu-with-sub-item.tsx" />
        </Stack>
      </div>
      <Popover open={popoverHook.open}
    // onClose={popoverHook.handleClose}
    anchorEl={popoverHook.anchorRef.current} onMouseEnter={popoverHook.handleOpen} onMouseLeave={popoverHook.handleClose} slotProps={{
      paper: {
        sx: {
          pointerEvents: "auto"
        }
      }
    }} anchorOrigin={{
      horizontal: "left",
      vertical: "bottom"
    }} sx={{
      pointerEvents: 'none',
      "& .MuiPaper-root": {
        borderRadius: "4px"
      }
    }} data-sentry-element="Popover" data-sentry-source-file="menu-with-sub-item.tsx">
        {data.subMenu.map((item, index) => <MenuItemCustom key={index} onClick={handleClickItem(item.path)} className={getClassNameActive(item, currentPath)}>
            {item.title}
          </MenuItemCustom>)}
      </Popover>
    </>;
};
export default MenuWithSubItem;