"use client";

import { useCallback, useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { useNProgress } from "@src/hooks/use-nprogress";
import { useWindowResize } from "@src/hooks/use-window-resize";
import TopNav from "./top-nav";
import SideNav from "./side-nav";
const Header = () => {
  const pathname = usePathname();
  const [openNav, setOpenNav] = useState(false);
  useNProgress();
  useWindowResize();
  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);
  useEffect(() => {
    handlePathnameChange();
  }, [pathname]);
  return <>
      <TopNav onNavOpen={() => setOpenNav(true)} data-sentry-element="TopNav" data-sentry-source-file="header.tsx" />
      <SideNav onClose={() => setOpenNav(false)} open={openNav} data-sentry-element="SideNav" data-sentry-source-file="header.tsx" />
    </>;
};
export default Header;