"use client"

import { useEffect } from "react"

function appHeight() {
  const doc = document.documentElement
  doc.style.setProperty("--100vh", window.innerHeight + "px")
}

export const useWindowResize = () => {
  useEffect(() => {
    if (window) {
      window._T = key => key
      window.addEventListener("resize", appHeight)
      appHeight()
    }
  }, [])
}
